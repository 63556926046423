export function hypenFix() {
	let all = document.getElementsByTagName("*");
	let newElem = [];
	let newText = [];
	let counter = 0;
	for (let i=0, max=all.length; i < max; i++) {
		 let elem = all[i];
		 let st = window.getComputedStyle(elem,null).getPropertyValue('font-family');
		if(st==='acta-display, sans-serif' || st==='Acta-display, sans-serif'){
			if(elem.innerHTML.indexOf('style')<0)
			{  
				let nst = elem.innerText.split('-').join('<span class="hiphen">-</span>');
				nst = nst.split('–').join('<span class="hiphen">–</span>');
				newText[counter] = nst;
				newElem[counter] = elem;
				counter++;
			}
		}
		if(i===(max-1)){
			for(let j=0;j<counter;j++){
				newElem[j].innerHTML = newText[j];
			}
		}
	}
}
export function createMarkup(txt) {
  return {__html: txt};
}
export function children({number,offset,parent}){
	return document.jsonPath+"wp-json/wp/v2/children?number="+number+"&offset="+offset+"&parent="+parent;
}
export function getPage(name,parent){
	if(parent!=null){
		return fetch(jsonUrl({ 
						number:1,
						type:'pages',
						slug:parent
					}))
				  .then(res => res.json())
				  .then(res => {
						return fetch(jsonUrl({ 
								number:1,
								type:'pages',
								slug:name,
								post_parent:res[0].id
						}))
						.then(res => res.json())
						.then(res => {
							document.title = res[0].yoast.title
							return res[0];		 
						}) 
				  })
	}
	else
		return fetch(jsonUrl({ 
						number:1,
						type:'pages',
						slug:name
					}))
				  .then(res => res.json())
				  .then(res => {
					document.title = res[0].yoast.title
					return res[0];		 
				  })
}
export function getFinal(type,name,related){
	return fetch(jsonUrl({ 
						number:1,
						type:type,
						slug:name
					}))
				  .then(res => res.json())
				  .then(res => {
					if(related!=null){
						return fetch(jsonUrl({ 
								number:3,
								type:type,
								orderbymeta:'menu_order',
								order:'desc',
								exclude:res[0].id
							}))
						  .then(res1 => res1.json())
						  .then(res1 => {							
							res[0].related = res1;	
							document.title = res[0].yoast.title;	
							return res[0];
						  })	
					}
					else{
						document.title = res[0].yoast.title;	
						return res[0];		 
					}
				  })
}
export function getList(type,number,orderby,order){
	if(number==null)
		number = 100;
	if(orderby==null)
		orderby = 'menu_order';
	if(order==null)
		order = 'asc';
	return fetch(jsonUrl({ 
						number:number,
						type:type,
						orderbymeta:orderby,
						order:order
					}))
				  .then(res => res.json())
				  .then(res => {
					return res;		 
				  })
}
export function getChildren(parent){
	{
		return fetch(jsonUrl({ 
						number:1,
						type:'pages',
						slug:parent
					}))
				  .then(res => res.json())
				  .then(res => {
						return fetch(jsonUrl({ 
								number:100,
								type:'pages',
								post_parent:res[0].id,
								orderbymeta:'menu_order',
								order:'asc'
						}))
						.then(res => res.json())
						.then(res => {
							return res;		 
						}) 
				  })
	}
}
export function getTerms(term_name){
	var urlParams = document.jsonPath+"wp-json/wp/v2/"+term_name;
	return urlParams;
}
export function getMenus({
	name=''
}){
	var urlParams = document.jsonPath+"wp-json/wp-api-menus/v2/menu-locations/"+name;
	return urlParams;
}
export function jsonUrl({number=1,
				 offset='',
				 type='',
				 id='',
				 post_parent='',
				 slug='',
				 meta_key='',
				 meta_value='',
				 orderbymeta='',
				 meta_type='',
				 order='',
				 meta_query='',
				 search='',
				 include='',
				 exclude='',
				 imageembed='',
				 pass=''
				 }){
	let urlParams = document.jsonPath+'wp-json/wp/v2/'; 
	let additionalParams = 0;
	if(type)
		urlParams = urlParams + type;
	if(id)
		urlParams = urlParams + '/' + id;
	
	urlParams = urlParams + '?';		
	additionalParams = 1;
	if(number){
		let numParams ='per_page=' + number;		
		if(additionalParams===1)
			urlParams = urlParams + '&' + numParams;
		else
			urlParams = urlParams + numParams;	
		additionalParams = 1;
	}
	if(slug){
		let slugParams = 'slug=' + slug;
		if(additionalParams===1)
			urlParams = urlParams + '&' + slugParams;
		else
			urlParams = urlParams + slugParams;
		additionalParams = 1;	
	}
	if(post_parent){
		let parentParams = 'parent=' + post_parent;
		if(additionalParams===1)
			urlParams = urlParams + '&' + parentParams;
		else
			urlParams = urlParams + parentParams;
		additionalParams = 1;	
	}
	if(offset){
		let offsetParams = 'offset=' + offset;
		if(additionalParams===1)
			urlParams = urlParams + '&' + offsetParams;
		else
			urlParams = urlParams + offsetParams;
		additionalParams = 1;	
	}		
	if(meta_key || meta_query){
		let metaParams = '';
		if(meta_key)
			metaParams = 'filter[meta_key]=' + meta_key;
		if(meta_value)	
			metaParams = metaParams + '&filter[meta_value]=' + meta_value;
		else if(meta_query){
			metaParams = metaParams + meta_query;
		}
		if(additionalParams===1)
			urlParams = urlParams + '&' + metaParams;
		else
			urlParams = urlParams + metaParams;
		additionalParams = 1;	
	}
	if(orderbymeta){
		let metaParams = 'orderby='+orderbymeta+'&order='+order;
		if(meta_type)
			metaParams = metaParams + '&filter[meta_type]='+meta_type;
		if(additionalParams===1)
			urlParams = urlParams + '&' + metaParams;
		else
			urlParams = urlParams + metaParams;
		additionalParams = 1;	
	}
	if(search){
		if(additionalParams===1)
			urlParams = urlParams + '&search='+search;
		else
			urlParams = urlParams + 'search='+search;
		additionalParams = 1;	
	}
	if(include){
		if(additionalParams===1)
			urlParams = urlParams + '&include='+include;
		else
			urlParams = urlParams + 'include='+include;
		additionalParams = 1;	
	}
	if(exclude){
		if(additionalParams===1)
			urlParams = urlParams + '&exclude='+exclude;
		else
			urlParams = urlParams + 'exclude='+exclude;
		additionalParams = 1;	
	}
	if(imageembed){
		if(additionalParams===1)
			urlParams = urlParams + '&_embed';
		else
			urlParams = urlParams + '_embed';
		additionalParams = 1;	
	}
	if(pass){
		if(additionalParams===1)
			urlParams = urlParams + '&password='+pass;
		else
			urlParams = urlParams + '&password='+pass;
		additionalParams = 1;	
	}
	return urlParams;
}