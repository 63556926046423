import React, { Component } from 'react';
import {hypenFix} from '../components/Factory';
import Kolumne from '../components/Kolumne';  
import {jarallax} from 'jarallax';

class KolumneContainer extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			imageUrl:'',
		}		
	}
	componentWillMount(){	
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		if(window.innerWidth>767)
			this.setState({
				imageUrl:this.props.page.desktop_image
			})
		else
			this.setState({
				imageUrl:this.props.page.mobile_image
			})
	}	
	componentDidMount(){
		hypenFix();	
		let dis = this;
		jarallax(document.querySelectorAll('.parallax'), {
			speed: 0.6
		});	
		window.onresize = function(){
			if(window.innerWidth>767)
				dis.setState({
					imageUrl:dis.props.page.desktop_image
				})
			else
				dis.setState({
					imageUrl:dis.props.page.mobile_image
				})	
		}
	}
	render() {
			let divStyle = {
					backgroundImage: 'url(' + this.state.imageUrl + ')'
			}
		   return (
				<div>	
					<div className="featured-content">
						<div className="overlay-bg"></div>
						<div className="gradient"></div>
						<div className="gradient-bottom"></div>
						<div style={divStyle} className="featured-img parallax"></div>
					</div>
					<Kolumne
						kolumne={this.props.kolumne}  
					/>
				</div>
            )       
	}
  
}
export default KolumneContainer;