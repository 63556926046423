import React, { Component } from 'react';
import {hypenFix} from '../components/Factory';

import Home from '../components/Home';  

class HomeContainer extends Component {
	componentWillMount(){	
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}	
	componentDidMount(){
		hypenFix();
	}
	render() {
		   return (
				<div>					
					<Home 	uber={this.props.uber} 
							leistungen={this.props.leistungen} 
							kolumne={this.props.kolumne}
							slider={this.props.slider}
							homePage={this.props.homePage}
					/>
				</div>
            )       
	}
  
}
export default HomeContainer;