import React, { Component } from 'react';
import {createMarkup} from './Factory'; 

class TeamItem extends Component {
		
	render() {
			const {title,role,featured_image} = this.props;
			let divStyle = {
				backgroundImage: 'url(' + featured_image + ')'
			}
		   return (
				<div className="col-md-4 col-sm-6 ht-fix">
					<div className="fixed-ht-img">
						<div className="img-wrapper" style={divStyle}></div>
					</div>
					<div className="info">
						<p dangerouslySetInnerHTML={createMarkup(title.rendered)}></p>
						<span dangerouslySetInnerHTML={createMarkup(role)}></span>
					</div>
				</div>
            )       
	}
  
}
export default TeamItem;