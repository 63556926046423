import React, { Component } from 'react';
import {hypenFix} from '../components/Factory';
import Leistungen from '../components/Leistungen';  
import {jarallax} from 'jarallax';

class LeistungenContainer extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			imageUrl:'',
		}		
	}
	componentWillMount(){	
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		if(window.innerWidth>767)
			this.setState({
				imageUrl:this.props.page.desktop_image
			})
		else
			this.setState({
				imageUrl:this.props.page.mobile_image
			})
		
	}	
	componentDidMount(){
		hypenFix();
		let dis = this;
		jarallax(document.querySelectorAll('.parallax'), {
			speed: 0.6
		});	
		window.onresize = function(){
			if(window.innerWidth>767)
				dis.setState({
					imageUrl:dis.props.page.desktop_image
				})
			else
				dis.setState({
					imageUrl:dis.props.page.mobile_image
				})	
		}
	}
	render() {
			let divStyle = {
					backgroundImage: 'url(' + this.state.imageUrl + ')'
			}
			let itemName = this.props.$stateParams.item;	
		   return (
				<div>	
					<div className="featured-content" >
						<div className="overlay-bg"></div>
						<div className="gradient"></div>
						<div className="gradient-bottom"></div>
						<div style={divStyle} className="featured-img parallax" /> 
					</div>
					<Leistungen
						leistungen={this.props.leistungen}  
						page={this.props.page} 
						kontakt={this.props.kontakt} 
						itemName={itemName}
					/>
				</div>
            )       
	}
  
}
export default LeistungenContainer;