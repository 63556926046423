import React, { Component } from 'react';
import { UIRouter, UIView, pushStateLocationPlugin,servicesPlugin } from '@uirouter/react';
import { CSSTransitionGroup } from 'react-transition-group'; 
import { ProgressBar } from 'react-fetch-progressbar';

import RootContainer from './containers/RootContainer';
import {getPage,getChildren,getList,getFinal} from './components/Factory';
import HomeContainer from './containers/HomeContainer'; 
import UberContainer from './containers/UberContainer'; 
import LeistungenContainer from './containers/LeistungenContainer'; 
import KolumneContainer from './containers/KolumneContainer'; 
import KolumneDetailsContainer from './containers/KolumneDetailsContainer'; 
import ImpressumContainer from './containers/ImpressumContainer'; 
import KontaktContainer from './containers/KontaktContainer'; 
import LeistungenItem from './components/LeistungenItem'; 
import DatenschutzerklarungContainer from './containers/DatenschutzerklarungContainer'; 
 
import './css/bootstrap.css';
import './style.css';

class App extends Component {
	
  render() {
	const configRouter = router => {
		router.locationConfig.baseHref(document.location.origin+"/");
	};
	const states = [
				{
					name:'root',
					url:'', 
					component:RootContainer,
					resolve:[
						{
							token: 'leistungen',
							resolveFn: () => getList('leistungen')
						},
						{
							token: 'kolumne',
							resolveFn: () => getList('kolumne',10,'date','desc')
						},
						{
							token: 'kontakt',
							resolveFn: () => getPage('kontakt')
						},
						{
							token: 'uber_uns',
							resolveFn: () => getPage('uber-uns')
						},
						{
							token: 'kolumne_page',
							resolveFn: () => getPage('kolumne')
						},
						{
							token: 'leistungen_page',
							resolveFn: () => getPage('leistungen')
						},
						{
							token: 'homePage',
							resolveFn: () => getPage('home')
						}
					],
				},
				{
					  name: 'root.home',
					  url: '/', 
					  component: HomeContainer,
					  resolve:[								  
								{
									token: 'slider',
									resolveFn: () => getList('slider',100)
								},
								{
									token: 'uber',
									resolveFn: () => getChildren('uber-uns')
								},
								{
									token: 'homePage',
									resolveFn: () => getPage('home')
								}	
							]		
				},
				{
					  name: 'root.uber',
					  url: '/uber-uns', 
					  component: UberContainer,
					  resolve:[								  
								{
									token: 'uber',
									resolveFn: () => getChildren('uber-uns')
								},
								{
									token: 'team',
									resolveFn: () => getList('team',100)
								},
								{
									token: 'uber_uns',
									resolveFn: () => getPage('uber-uns')
								},	
							]		
				},
				{
					  name: 'root.leistungen',
					  url: '/leistungen', 
					  component: LeistungenContainer,
					  resolve:[ 
								{
									token: 'page',
									resolveFn: () => getPage('leistungen')
								}
							]
					  
				},
				{
					  name: 'root.leistungen.details',
					  url: '/:item', 
					  component: LeistungenItem,
					  resolve:[
								{
									token: 'page',
									deps: ['$transition$'],
									resolveFn: (trans) => getFinal('leistungen',trans.params().item)
								}
							]		
				},
				{
					  name: 'root.kolumne',
					  url: '/kolumne', 
					  component: KolumneContainer,
					  resolve:[ 								
								{
									token: 'page',
									resolveFn: () => getPage('kolumne')
								}
							]		
				},
				{
					  name: 'root.kolumnedetails',
					  url: '/kolumne/:item', 
					  component: KolumneDetailsContainer,
					  resolve:[ 								
								{
									token: 'page',
									deps: ['$transition$'],
									resolveFn: (trans) => getFinal('kolumne',trans.params().item,1)
								}
							]		
				},
				{
					  name: 'root.impressum',
					  url: '/impressum', 
					  component: ImpressumContainer,
					  resolve:[ 								
								{
									token: 'page',
									resolveFn: () => getPage('impressum')
								}
							]		
				},
				{
					  name: 'root.datenschutzerklarung',
					  url: '/datenschutzerklarung', 
					  component: DatenschutzerklarungContainer,
					  resolve:[ 								
								{
									token: 'page',
									resolveFn: () => getPage('datenschutzerklarung')
								}
							]		
				},
				{
					  name: 'root.kontakt',
					  url: '/kontakt', 
					  component: KontaktContainer,
					  resolve:[ 								
								{
									token: 'page',
									resolveFn: () => getPage('kontakt')
								}
							]	
				}
	];
	const plugins = [
			  pushStateLocationPlugin,
			  servicesPlugin
		];
	  
    return (
		<UIRouter plugins={plugins} states={states} config={configRouter}>
				<div>
					<ProgressBar style={{ backgroundColor: 'black', height: '2px',position: 'fixed' }} />
												
					<UIView render={(Comp, props) =>
					  <CSSTransitionGroup 
						transitionName="example"
						transitionEnterTimeout={250}
						transitionLeaveTimeout={250}>
						<Comp {...props} key={props.transition} />
					  </CSSTransitionGroup>
					}/>
				</div>
			</UIRouter>
    );
  }
}

export default App;
