import React, { Component } from 'react';
import {UISref,UISrefActive} from '@uirouter/react'; 
import logo from '../images/Notar-Schwarz-Logo-Neg.png';
import call from '../images/phone.svg';
import close from '../images/close.svg';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			navState:'',
			cookieClass:''
		}
		this.navToggle = this.navToggle.bind(this);
		this.handleMenu = this.handleMenu.bind(this);
		this.handleCookie = this.handleCookie.bind(this);
	}
	navToggle(){
		let newState = (this.state.navState) ? '' : 'mobile-nav-open';
		this.setState({
			navState:newState
		})
	}
	handleMenu(){
		this.setState({
			navState:''
		})
	}
	handleCookie(){
		document.cookie = 'cookieMessage=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';	
		this.setState({
			cookieClass:'close'
		})
		document.body.classList.remove('cookiebar');
	}
	render() {
		const {slug} = this.props.leistungen[0];
		const {kontakt_phone}  = this.props.homePage;
		let cookie = true;
		if (document.cookie.indexOf('cookieMessage=true') > -1) {
			cookie = false;
		}
		else	
			document.body.classList.add('cookiebar');
		
		return (
			<div>
				<header className={this.state.navState} >
					<div className="bg-img">
						<div className="container-fluid">
							<div className="row">
								<div className="site-header">
									<div className="col-md-12">
										<div className="logo" onClick={this.handleMenu}>
											<UISref to="root.home">
												<a><img alt="Logo" src={logo} /></a>
											</UISref>	
										</div>
										
										<ul className="main-nav">
											<div className="mobile-nav">
												<li onClick={this.handleMenu}>
													<UISrefActive class="active">
														<UISref to="root.home">
															<a >Home</a>
														</UISref>
													</UISrefActive>
												</li>
												<li onClick={this.handleMenu}>
													<UISrefActive class="active">
														<UISref to="root.uber">
															<a >Über uns</a>
														</UISref>
													</UISrefActive>
												</li>													
												<li onClick={this.handleMenu}>
													<UISrefActive class="active">
														<UISref to="root.leistungen.details" params={{item:slug}}>
															<a>Leistungen</a>
														</UISref>
													</UISrefActive>
												</li>	
												<li onClick={this.handleMenu}>
													<UISrefActive class="active">
														<UISref to="root.kolumne">
															<a>Kolumne</a>
														</UISref>
													</UISrefActive>
												</li>	
												<li onClick={this.handleMenu}>
													<UISrefActive class="active">
														<UISref to="root.kontakt">
															<a>Kontakt</a>
														</UISref>
													</UISrefActive>
												</li>	
											</div>
										</ul>
										<div className="toggle-wrapper" onClick={this.navToggle}>
											<a id="nav-toggle"><span></span></a>
										</div> 
										<a href={`tel:${kontakt_phone}`}>
											<img className="call-btn" alt="Call" src={call} />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		)
	}
}
export default Header; 