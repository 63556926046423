import React, { Component } from 'react';
import {hypenFix} from '../components/Factory';
import KolumneDetail from '../components/KolumneDetail';  

class KolumneDetailsContainer extends Component {
	componentWillMount(){	
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}	
	componentDidMount(){
		hypenFix();
	}
	render() {
		   return (
				<div>					
					<KolumneDetail
						{...this.props.page}  
					/>
				</div>
            )       
	}
  
}
export default KolumneDetailsContainer;  