import React, { Component } from 'react';
import {UISref} from '@uirouter/react'; 
import {createMarkup} from './Factory';
import {Power4,TimelineLite} from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Hero from '../components/Hero'; 
import UberItem from '../components/UberItem'; 
import KolumnenItem from '../components/KolumnenItem'; 
import Slider from "react-slick";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mobile:'',
			pageYOffset:'',
			winWt:window.innerWidth,
			winHt:window.innerHeight
		}
		this.slider = this.slider.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}
	handleScroll(){
		window.TweenLite.to(window, 0.5, {scrollTo:window.innerHeight});
	}
	componentDidMount(){
		document.querySelector('.site-footer').classList.add('homepage');
		let dis = this;
			
		window.TweenLite.set(document.getElementsByClassName("jarallax")[0],{autoAlpha:1,className: '+=active'});		
		
		setTimeout(function(){
			dis.slider();
			
		},1000)
		window.addEventListener('resize',this.resize.bind(this));
	} 
	resize(){
		this.setState({
			winWt:window.innerWidth
		});		
	}
	slider(){
		document.querySelectorAll('.bar')[0].classList.add('active');	
		this.autoTimer = setInterval(function () {
			if(document.hasFocus){
				let tl = new window.TimelineLite(),
					slides = document.getElementsByClassName("jarallax"),
					slideOut = document.getElementsByClassName("jarallax active")[0], 
					slideIn = document.getElementsByClassName("jarallax active")[0].nextSibling,
					barsAll = document.querySelectorAll('.bar'), 
					bars = document.querySelectorAll('.bar.active'), 
					barLast = bars[bars.length-1].nextSibling;
				if(!barLast){
					for (var i = 0; i < barsAll.length; i++) {
					   barsAll[i].classList.remove('active');
					}
					barLast = document.querySelectorAll('.bar')[0];
				}	
					
				if(!slideIn)
					slideIn = document.getElementsByClassName("jarallax")[0];
				
					tl
					.set(barLast,{className: '+=active'})
					.set(slides,{zIndex:0})
					.set(slideOut, {x: '0%', autoAlpha: 1,zIndex:2,className: '-=active'})
					.set(slideIn, {x: '100%', autoAlpha: 1,zIndex:3, className: '+=active'})
					.to(slideOut, 1.5, {x: '-30%',zIndex:2, ease:Power4.easeInOut}, 0)
					.to(slideIn, 1.5, {x: '0%',zIndex:3, ease:Power4.easeInOut}, 0);
					
			}
			
		},4000);
		
	}
	componentWillUnmount(){
		clearInterval(this.autoTimer);
		clearInterval(this.autoTimerUber);
		document.querySelector('.site-footer').classList.remove('homepage');
	}
	
	render() {
		let slider = this.props.slider;
		let uber = this.props.uber;
		let leistungen = this.props.leistungen;
		let kolumne = this.props.kolumne;
		let homePage = this.props.homePage;
		
		let settings = {
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			adaptiveHeight: true,
			arrows:false
		};
		return (
			<div className="home">
				<div className="slider" style={{height:this.state.winHt}}>
					<div>
					{
						slider.map( (data,i)=> {							
							return 	<Hero key={i} {...data} />
						})		
					}
					</div>
					<div className="container-fluid">							
						<div className="slider-txt-wrapper">
							<div className="slider-txt" dangerouslySetInnerHTML={createMarkup(homePage.content.rendered)}></div>
						</div>											
					</div>
					<div className="container-fluid">
						<ul className="statusbar">
						{
							slider.map( (data,i)=>{
								return <li key={i} className="bar"><span></span></li>
							})
						}
						</ul>						
					</div>
					<div className="bottom-right" onClick={this.handleScroll}>
						<div className="container-fluid">
							<a className="scroll-btn">
								<i className="arrow arrow-one"></i>
								<i className="arrow arrow-two"></i>
							</a>
						</div>
					</div>
				</div>
				<div className="article-wrapper">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-3 article">
								<h1 className="title highlight">Über uns</h1>
							</div> 
							<div>
							{
								(this.state.winWt>767)?
									uber.map( (data,i)=>{
										let rowLen = uber.length,last;
										if(rowLen=== i+1)
											last = true;
										else
											last  = false;
										return <UberItem jarallax={this.state.mobile} last={last} key={i} {...data}/>
									})
								: null
							}
							</div>
							{
								(this.state.winWt<768)?
									<Slider {...settings}>
										{
											uber.map( (data,i)=>{
												let rowLen = uber.length,last;
												if(rowLen=== i+1)
													last = true;
												else
													last  = false;
												return <UberItem jarallax={this.state.mobile} last={last} key={i} {...data}/>
											})
										}
									</Slider>
								:null
							}
							<div className="col-md-12 clearfix">
								<div className="border-bottom"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="services-wrapper">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-3 services">
								<h1 className="highlight">Leistungen</h1>
							</div>
							
							<div className="col-md-9 services">
								<ul>
									{
										leistungen.map( (data,i)=>{
											
											return <li key={i}>
														<UISref to="root.leistungen.details" params={{item:data.slug}} >
															<a dangerouslySetInnerHTML={createMarkup(data.title.rendered)}></a>
														</UISref>
													</li>
										})
									}
								</ul>
							</div>
							<div className="col-md-12">
								<div className="border-bottom"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="column-wrapper">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-3 column-title">
								<h1 className="title highlight">Kolumnen</h1>
							</div>
							{
								(this.state.winWt>767)?
									kolumne.slice(0,3).map( (data,i)=>{
										return <KolumnenItem key={i} {...data}/>
									})
								:null
							}
							{								
								(this.state.winWt<768)?
									<Slider {...settings}>
									{
										kolumne.slice(0,3).map( (data,i)=>{
											return <KolumnenItem key={i} {...data}/>
										})
									}
									</Slider>
								:null	
							}
							<div className="col-md-12 clearfix">
								<div className="border-bottom"></div>
							</div>	
						</div>
					</div>
				</div>
			  
			</div> 
		);
	}
}

export default Home; 