import React, { Component } from 'react';
import {createMarkup,hypenFix} from '../components/Factory'; 
import Uber from '../components/Uber';  
import Team from '../components/Team';  
import {jarallax} from 'jarallax';

class UberContainer extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			imageUrl:'',
		}		
	}
	componentWillMount(){	
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		if(window.innerWidth>767)
			this.setState({
				imageUrl:this.props.uber_uns.desktop_image
			})
		else
			this.setState({
				imageUrl:this.props.uber_uns.mobile_image
			})	
	}	
	componentDidMount(){
		hypenFix();
		jarallax(document.querySelectorAll('.parallax'), {
			speed: 0.6
		});	
		window.addEventListener('resize',this.resize.bind(this));
	}
	resize(){
		jarallax(document.querySelectorAll('.jarallax'), 'destroy');	
		if(window.innerWidth>767)
			this.setState({
				imageUrl:this.props.uber_uns.desktop_image
			})
		else
			this.setState({
				imageUrl:this.props.uber_uns.mobile_image
			})
		setTimeout(function(){
				window.jarallax(document.querySelectorAll('.parallax'), {
					speed: 0.6
				});		
			},500);	
	}
	render() {
			let divStyle = {
					backgroundImage: 'url(' + this.state.imageUrl + ')'
			}
			let { title,content } = this.props.uber_uns;
			const { uber,team } = this.props;
		   return (
				<div>	
					<div className="featured-content">
						<div className="gradient"></div>
						<div className="gradient-bottom about"></div>
						<div className="overlay-bg"></div>
						<div style={divStyle} className="featured-img parallax" />
					</div>
					<div className="article-wrapper">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="about-wrapper">
										<h1 dangerouslySetInnerHTML={createMarkup(title.rendered)}></h1>
										
										<div className="about-content clearfix">
											<div className="left-section">
												<p>Unser Ziel</p>
											</div>

											<div className="right-section" dangerouslySetInnerHTML={createMarkup(content.rendered)}></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Uber uber={uber} />
					<Team team={team} />
				</div>
            )       
	}
  
}
export default UberContainer;