import React, { Component } from 'react';
import UberItem from '../components/UberItem'; 
import Slider from "react-slick";

class Uber extends Component {
	componentWillMount(){	
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}	
	constructor(props) {
		super(props);
		this.state = {
			winWt:window.innerWidth
		}
	}
	render() {
			let settings = {
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				adaptiveHeight: true,
				arrows:false
			};
		   return (
					<div className="article-wrapper uber">
						<div className="container-fluid">
							<div className="row bottom">
								<div className="col-md-3 article">
									<h1 className="title head">Unsere Philosophie</h1>
								</div>
								{									
									(this.state.winWt>767)?
										this.props.uber.map( (data,i)=>{
											return <UberItem key={i} {...data}/>
										})
									:null
								}
								{
									(this.state.winWt<768)?
										<Slider {...settings}>
										{
											this.props.uber.map( (data,i)=>{
												return <UberItem key={i} {...data}/>
											})
										}
										</Slider>
									:null
								}
								<div className="container-fluid">
									<ul className="statusbar">
										<li className="bar"><span></span></li>
										<li className="bar"><span></span></li>
										<li className="bar"><span></span></li>
										
									</ul>						
								</div>
								<div className="col-md-12">
									<div className="border-bottom"></div>
								</div>
							</div>
						</div>
					</div>					
            )       
	}
  
}
export default Uber;