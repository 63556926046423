import React, { Component } from 'react';
import {createMarkup,jsonUrl} from './Factory';
import {UISref} from '@uirouter/react';   

class Kolumne extends Component {
	constructor(props) {
		super(props);
		this.state = {
			kolumne : this.props.kolumne,
			offset:10,
			finished:1
		}
		this.loadMore = this.loadMore.bind(this);
	}
	loadMore(){
		return fetch(jsonUrl({ 
						number:5,
						type:'kolumne',
						orderbymeta:'date',
						order:'desc',
						offset:this.state.offset
					}))
				  .then(res => res.json())
				  .then(res => {
					if(res.length>0)
						this.setState({
							kolumne : [...this.state.kolumne,...res],
							offset: this.state.offset+5
						})
					else	
						this.setState({
							finished:0
						})
				  })
		
	}	
	render() {
			let month = '';
			let show = '';
			let total = this.state.kolumne.length-1;
		   return (
				<div className="column-page-wrapper">
					<div className="container-fluid">	
						<div className="row">
							<div className="col-md-12">
								<h1 className="title">Kolumne</h1>
							</div>
						</div>
						{
							this.state.kolumne.map( (data,i)=>{
								let current_month = data.date_month;
								let short_class = '';
								if(current_month!==month && i<5){
									month = current_month;
									show = current_month;
								}
								else{
									if(i===5)
										show = 'Weitere Artikel';
									else
										show = '';
									if(i>4)
										short_class = 'short';
								}
								
								return	<div className={`row bottom ${short_class}`} key={i}>
											<div className="col-md-4 date dull">
												<p>{show}</p>
											</div>
											<div className="col-md-8 columns-article">
												<div className="article">
													<UISref key={i} to="root.kolumnedetails" params={{item:data.slug}} >
														<a><h1 className="dull" dangerouslySetInnerHTML={createMarkup(data.title.rendered)}></h1></a>
													</UISref>
													<p className="date dull">{data.date}</p>
													{
														(i<5)?
														<div>
														<div className="dull" dangerouslySetInnerHTML={createMarkup(data.excerpt.rendered)}></div>
														<UISref key={i} to="root.kolumnedetails" params={{item:data.slug}} >
															<a className="readmore highlight">weiterlesen</a>
														</UISref>
														</div>
													:null
													}
													{
														(i===total && this.state.finished)?
															<span onClick={this.loadMore} className="readmore loadmore highlight">Mehr laden</span>
														:null													
													}
												</div>
											</div>
											
										</div> 	
							})
						}
					</div>
				</div>
            )       
	}
  
}
export default Kolumne;