import React, { Component } from 'react';
import TeamItem from '../components/TeamItem'; 

class Team extends Component {
		
	render() {
		   return (
				<div className="team-wrapper">
					<div className="container-fluid">
						<div className="row border-bottom">
							<div className="team">
							{
								this.props.team.map( (data,i) => {
									return <TeamItem key={i} {...data} />
								})
							}
							</div>
						</div>
					</div>
				</div>
            )       
	}
  
}
export default Team;