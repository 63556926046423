import React, { Component } from 'react';
import {createMarkup} from './Factory'; 
import {UISref} from '@uirouter/react'; 

class KolumnenItem extends Component {
		
	render() {
			const {title,excerpt,date,slug} = this.props;
		   return (
				<UISref to="root.kolumnedetails" params={{item:slug}} className="col-md-3 col-sm-4 column">
					<a>
					<p className="titlelight dull" dangerouslySetInnerHTML={createMarkup(title.rendered)}></p>
					<span className="date dull">{date}</span>
					<div className="highlight" dangerouslySetInnerHTML={createMarkup(excerpt.rendered)}></div>
					<span className="readmore highlight">weiterlesen </span>
					</a>
				</UISref>
            )       
	}
  
}
export default KolumnenItem;