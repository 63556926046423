import React, { Component } from 'react';
import {createMarkup} from './Factory';

class Datenschutzerklarung extends Component {	
	constructor(props) {
		super(props);		
		this.deactivateAnalytics = this.deactivateAnalytics.bind(this);
	}
	deactivateAnalytics(){
		let disableStr = 'ga-disable-'+window.gaProperty;		
		document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
		window[disableStr] = true;
		document.getElementById('deactivate').innerHTML = "Google Analytics wurde deaktiviert";
	}
	render() {
			const {
					title,impressum_heading,impressum_text
			} = this.props;
			let total = impressum_heading.length-1;
		   return (
				<div className="impressum-wrapper">		
					<div className="container-fluid">
						<div className="row bottom">
							<div className="col-md-12">
								<h1 className="title">{title.rendered}</h1>
							</div>
						</div>
						
						{
							impressum_heading.map( (data,i)=>
								<div className="row bottom" key={i}>	
									<div className="col-md-5 left-section highlight">
										<p>{data}</p>
									</div>
									
									<div className="col-md-7 right-section">
										<p dangerouslySetInnerHTML={createMarkup(impressum_text[i])}></p>
										{	
											(i==total)?
											<a href="javascript:void(0)" id="deactivate" onClick={this.deactivateAnalytics}>Google Analytics deaktivieren</a>
											:null
										}		
									</div>
								</div>
							)		
						}
					</div>
				</div>
            )       
	}
  
}
export default Datenschutzerklarung;  