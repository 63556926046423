import React, { Component } from 'react';
import {createMarkup} from './Factory';
import KolumnenItem from '../components/KolumnenItem'; 

class KolumneDetail extends Component {
	
	render() {
			const { title,content,excerpt,related,date }  =  this.props;
		   return (
				<div>	
					
					<div className="column-single-wrapper">
						<div className="container-fluid">
							<div className="row bottom">
								<div className="col-md-12">
									<h1 className="title">Kolumne</h1>
								</div>
							</div>
							<div className="row bottom">								
								<div className="col-md-3 date highlight">
									<p>{date}</p>
								</div>
								<div className="col-md-6 single-article">
									<div className="article">
										<h1 className="dull" dangerouslySetInnerHTML={createMarkup(title.rendered)}></h1>
										<div className="intro_text" dangerouslySetInnerHTML={createMarkup(excerpt.rendered)}></div>
										<div className="normal content dull" dangerouslySetInnerHTML={createMarkup(content.rendered)}></div>
									</div>
									
								</div>
								
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="border-bottom"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="column-wrapper">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-3 column-title">
									<h1 className="title highlight">Weitere Kolumnen</h1>
								</div>
								{
									related.map( (data,i)=>{
										return <KolumnenItem key={i} {...data}/>
									})
								}
								<div className="col-md-12 clearfix">
									<div className="border-bottom"></div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
            )       
	}
  
}
export default KolumneDetail;  