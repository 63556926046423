import React, { Component } from 'react';
import {createMarkup} from './Factory'; 
import {UISref} from '@uirouter/react'; 

class UberItem extends Component {
		
	render() {
			const {title,content,last,jarallax} = this.props;
		   return (
				<div className={`col-md-3 col-sm-4 article ${jarallax}`}>
					<p className="title highlight" dangerouslySetInnerHTML={createMarkup(title.rendered)}></p>
					<div className="dull" dangerouslySetInnerHTML={createMarkup(content.rendered)}></div>
					{
						(last) ?
						<UISref to="root.uber">
							<a className="highlight">Mehr über uns</a>
						</UISref>	
						:null
					}
				</div>
            )       
	}
  
}
export default UberItem;