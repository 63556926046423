import React, { Component } from 'react';
import {jarallax} from 'jarallax';

class Hero extends Component {
	constructor(props) {
		super(props);
		this.state = {
			slideImage:'',
			opacity:0,
			winHt:window.innerHeight
		}
	}	
	componentDidMount(){
		window.addEventListener('resize',this.resize.bind(this));
		setTimeout(function(){
			jarallax(document.querySelectorAll('.jarallax .bg'), {
				speed: 0.6
			});	
		},500);
	}
	resize(){
		if(window.innerWidth>767)
			this.setState({
				slideImage:this.props.desktop_image
			})
		else
			this.setState({
				slideImage:this.props.mobile_image
			})	
	}
	componentWillMount(){
		if(window.innerWidth>767)
			this.setState({
				slideImage:this.props.desktop_image
			})
		else
			this.setState({
				slideImage:this.props.mobile_image
			})
			
	}
	render() {
		   return (
				<div className="featured-content jarallax">
					<div className="gradient"></div>
					<div className="gradient-bottom"></div>
					<div className="overlay-bg"></div>
					<div className="bg" style={{height:this.state.winHt,backgroundImage: 'url('+this.state.slideImage+')'}}>
					</div>										
				</div>
            )       
	}
  
}
export default Hero;