import React, { Component } from 'react';
import {createMarkup,hypenFix} from './Factory'; 
import ScrollToPlugin from "gsap/ScrollToPlugin";

class LeistungenItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggleClass:''
		}
	}
	componentWillMount(){
		window.TweenLite.to(window, 0.5, {scrollTo:0});	
		if(this.props.activeItem===this.props.page.slug)
			this.setState({
				toggleClass:'is_active'
			});
	}
	componentWillReceiveProps(nextProps){
		if(nextProps.activeID===this.props.page.id ){
			if(this.state.toggleClass===''){
				this.setState({
					toggleClass:'is_active'
				})
			}
			else{
				this.setState({
					toggleClass:''
				})
			}
		}
	}
	componentDidMount(){
		hypenFix();
	}
	render() {
			const {title,content} = this.props.page;
			return (
				<div className={`col-md-7 col-sm-6 service-info dull ${this.state.toggleClass} `}>
					<h1 dangerouslySetInnerHTML={createMarkup(title.rendered)}></h1>
					<div dangerouslySetInnerHTML={createMarkup(content.rendered)}></div>
				</div>
            )       
	}
  
}
export default LeistungenItem;