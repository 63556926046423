import React from 'react';
import ReactDOM from 'react-dom';
import { progressBarFetch, setOriginalFetch } from 'react-fetch-progressbar';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

setOriginalFetch(window.fetch);
window.fetch = progressBarFetch; 

document.jsonPath = 'https://www.notar-schwarz.at/';
document.ajaxPath = 'https://www.notar-schwarz.at/wp-admin/admin-ajax.php';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
