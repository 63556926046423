import React, { Component } from 'react';
import { UIView} from '@uirouter/react';
import { CSSTransitionGroup } from 'react-transition-group';  

import Header from '../components/Header';  
import Footer from '../components/Footer';  

class RootContainer extends Component { 
	constructor(props) {
		super(props);
		this.state = { 
			divStyle:''
		}
	}
	componentDidMount(){
		const { uber_uns,kontakt,kolumne_page,leistungen_page } = this.props;
		setTimeout(function(){
			var loader = document.getElementById('loader');
			if(loader)
				loader.classList.add('hide');
		},1000);
		let dis = this;
		setTimeout(function(){
			if(window.innerWidth>767){
				dis.setState({
					divStyle : 'url(' + uber_uns.desktop_image + '),'+
							'url(' + kontakt.desktop_image + '),'+
							'url(' + kolumne_page.desktop_image + '),'+
							'url(' + leistungen_page.desktop_image + ')'
				})
			}
			else{
				dis.setState({
					divStyle : 'url(' + uber_uns.mobile_image + '),'+
							'url(' + kontakt.mobile_image + '),'+
							'url(' + kolumne_page.mobile_image + '),'+
							'url(' + leistungen_page.mobile_image + ')'
				})	
			}
		},2000);
	}
	
	render() {				
			const { kontakt,leistungen,kolumne,homePage } = this.props;
		   return (
				<div>
					<div style={{backgroundImage:this.state.divStyle}}></div>
					<Header homePage={homePage} leistungen={leistungen} />
					<UIView render={(Comp, props) =>
					  <CSSTransitionGroup
						transitionName="example"
						transitionEnterTimeout={250}
						transitionLeaveTimeout={250}>
						<Comp {...props} key={props.transition} />
					  </CSSTransitionGroup>
					}/>
					<Footer leistungen={this.props.leistungen} 
							kolumne={kolumne} 
							kontakt={kontakt} 
							/>
				</div>
			)
	   
	}
}
export default RootContainer;   